import { useEffect, useState, useCallback } from 'react'
import { Button } from '../../../../components/Button'
import * as S from './styles'
import fetchAxios, { fetchAxiosAdmin } from 'services/http'
import { format, addDays } from 'date-fns'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useAnyProvider } from 'hooks/Context'
import { usePaymentProvider } from 'context/paymentContext'
import { useDateDifference } from 'hooks/useDateDifference'
import { useUserProvider } from 'hooks/userContext'
import useAlert from 'components/Notification'
import { Tooltip } from 'components/Tooltip'
import Skeleton from 'react-loading-skeleton'
import dayjs from 'dayjs'
import { generateReserveServices } from 'utils/generateReserveServices'
import { useSessionStorage } from 'context/sessionStorage'
import { CalendarComponent } from 'components/Calendar'
import { formatDateMapped } from 'utils/formatDate'
import { ReserveDetails } from 'pages/Payment/components/PaymentCard/types'
import { ReserverDataProps } from 'pages/Product/types'
import usePaymentStore, { DataSessionPaymentProps } from 'pages/Payment/components/PaymentCard/useStorage'
import useExpensesStore from 'hooks/useExpensesStorage'
import { useCancellationPolicies } from 'components/CancellationPolicies/useCancellationPolicies'
import { CheckIcon } from 'assets/svg/payment/Check'
import { GuestSelector } from 'components/Header/components/GuestSelector'
import { useGuestStore } from 'components/Header/components/useGuestStorage'

interface CustomStayCardProps {
    onClick: () => void;
    onClickCupom: () => void;
    discount: boolean;
    reservedDays?: any;
    accomodationId: string | number | undefined
    setReserveData: ReserveDetails,
    isFetching: boolean,
    minimumStay: number
}

export type Service = {
    id: string;
    created_at: null;
    updated_at: null;
    code: null | number;
    name: string;
    portal_commission: null;
    specific_taxation: null;
    value: string;
    show_on_website: boolean;
    service_id: number;
    accommodation_id: number;
};

const pixIcon = require('../../../../assets/svg/payment/PixNewIcon.svg').default

const creditCardIcon = require('../../../../assets/svg/payment/CreditCard.svg').default

export const CustomStayCard = ({ onClick, discount, reservedDays, accomodationId, setReserveData, isFetching, minimumStay }: CustomStayCardProps) => {
    const [minGuests, setMinGuests] = useState<number | null>(null)
    const [searchParams, setSearchParams] = useSearchParams();
    const [maxGuest, setMaxGuest] = useState<number | null>(null)
    const [guest, setGuest] = useState<number>(0)
    const [idAccomodation, setIdAccomodation] = useState<string | number | undefined>(accomodationId)
    const [valueWithTax, setValueWithTax] = useState<number>(0)
    const [totalValueWithTax, setTotalValueWithTax] = useState<number>(0)
    const [additionalValueGuest, setAdditionalValueGuest] = useState<number>(0)

    const { totalGuests } = useGuestStore();

    const [blockedDates, setBlockedDates] = useState<any>([])

    const [guestAddtional, setGuestAddtional] = useState<number | null>(null)
    const { saveToSessionStorage } = useSessionStorage()
    const { userEmail, userName, userSurname, userDocument, userPhone, userCustomerId } = useUserProvider()
    const [hasTaxSearch, setHasTaxSearch] = useState(false)
    const { setPaymentData } = usePaymentStore();

    const { Alert, showAlert } = useAlert({ active: false, title: '', type: "SUCCESS" })

    const { date, setDate, setReservationDetails, reservationDetails } = useAnyProvider()

    const { dayDifference: difference, weeklyDifference: weekValue } = useDateDifference(date)

    const navigate = useNavigate()

    const { cancelDate, dayDifference: daysPermitedToCancel } = useCancellationPolicies({ date: date });

    const { setTotalValue, setReserveDetails, totalValue: totalReserveValue, totalValue: totalV, creditCardToken } = usePaymentProvider()

    const { expensesData, setExpensesData } = useExpensesStore();
    const location = useLocation()


    const queryParams = new URLSearchParams(location.search);
    const includeTax = queryParams.get('includeTax') === 'true';

    useEffect(() => {
        if (difference > 26) {
            if (minGuests && guestAddtional && totalGuests > minGuests) {
                let addtionalGuests = totalGuests - minGuests
                let addtionalValue = (addtionalGuests * guestAddtional) * difference
                setAdditionalValueGuest(((totalGuests - minGuests) * guestAddtional) + addtionalValue)
            }

        } else {
            if (minGuests && guestAddtional && totalGuests > minGuests) {
                let addtionalGuests = totalGuests - minGuests
                let addtionalValue = (addtionalGuests * guestAddtional) * difference
                setAdditionalValueGuest(addtionalValue)
            }
        }
        if (minGuests && totalGuests <= minGuests) {
            setAdditionalValueGuest(0)
        }
    }, [totalGuests, difference, guestAddtional])

    const totalValue = useCallback((difference: number) => {
        let sum = 0
        if (difference > 26) {
            if (discount && expensesData && discountDifference) {
                sum = discountDifference + expensesData.condominiumValue + expensesData.iptuValue + expensesData.yoghaPackageTax + (weekValue * expensesData.cleaningValue)
                return sum.toFixed(0)
            }
            //conta do valor total
            if (minGuests !== null && totalGuests !== null && guestAddtional !== null && totalGuests > minGuests && expensesData) {
                let addtionalGuests = totalGuests - minGuests
                let addtionalValue = (addtionalGuests * guestAddtional) * difference
                sum = expensesData.midStay + expensesData.condominiumValue + expensesData.iptuValue + expensesData.yoghaPackageTax + (weekValue * expensesData.cleaningValue) + ((totalGuests - minGuests) * guestAddtional) + addtionalValue
                return sum.toFixed(0)
            } else {
                expensesData && (sum = expensesData.midStay + expensesData.condominiumValue + expensesData.iptuValue + expensesData.yoghaPackageTax + (weekValue * expensesData.cleaningValue))
                return sum.toFixed(0)
            }
        } else if (difference > 0 && difference <= 26) {
            if (discount && expensesData && discountDifference) {
                sum = discountDifference + (weekValue * expensesData.cleaningValue)
                return sum.toFixed(0)
            }
            if (minGuests !== null && totalGuests !== null && guestAddtional !== null && totalGuests > minGuests && expensesData) {
                let addtionalGuests = totalGuests - minGuests
                let addtionalValue = (addtionalGuests * guestAddtional) * difference
                sum = expensesData.definedDailyValue + (weekValue * expensesData.cleaningValue) + addtionalValue
                return sum.toFixed(0)
            } else {
                expensesData && (sum = expensesData.definedDailyValue + (weekValue * expensesData.cleaningValue))
                return sum.toFixed(0)
            }
        } else {
            return 0
        }

    }, [discount, expensesData, minGuests, totalGuests, guestAddtional, weekValue]);

    const realTotalValue = totalValue(difference)

    const getDailyValue = async () => {
        const filterServices = (services: Service[], filterString: string) => {
            return services.filter(service => service.name.includes(filterString));
        };
        const checkin = formatDateMapped(date?.[0] as string)
        const checkout = formatDateMapped(date?.[1] as string)
        try {
            const id = accomodationId
            if (date?.length !== 0) {
                const currentDate = format(new Date(), 'yyyy-MM-dd');
                const nextDay = format(addDays(new Date(), 1), 'yyyy-MM-dd');
                const response = await fetchAxios.post(`/accommodations/accommodationValue/${id}`, {
                    checkIn: date && date.length > 0 ? checkin : currentDate,
                    checkOut: date && date.length > 0 ? checkout : nextDay,
                })
                const filteredFinalCleaningServices = filterServices(response.data.services, "Limpeza Final");
                const filteredExtraCleaningServices = filterServices(response.data.services, "Limpeza Extra");
                const reserveServiceArray = generateReserveServices(filteredFinalCleaningServices, filteredExtraCleaningServices, weekValue);
                const valueCleaning = filteredFinalCleaningServices.length > 0 ? filteredFinalCleaningServices[0].value : 0
                const midStay = difference > 31 ? (Number(response.data.midStayTaxa) / 30) * (difference) : Number(response.data.midStayTaxa)
                const sumMidStay = Number(midStay.toFixed(0))
                setReservationDetails({
                    accommodation_code: response.data.code,
                    accommodation_id: response.data.idAccommodations,
                    ref_stays: response.data.ref_stays,
                    hostingValue: realTotalValue as number,
                    amount: '1',
                    city: response.data.town,
                    code: filteredFinalCleaningServices.length > 0 ? filteredFinalCleaningServices[0].service_id : 0,
                    reserveServices: reserveServiceArray,
                    nameAcc: response.data.accommodation,
                    nameBuilding: response.data.nameBuilding
                })
                setMinGuests(response.data.minimun_guests)
                setMaxGuest(response.data.max_guest_capacity)
                setGuestAddtional(response.data.guest_addtional)
                if (date && date?.length > 0 && difference > 26) {
                    const rateCondominium = Math.floor(response.data.condominium)
                    const rateIptu = Math.floor(response.data.iptu)
                    const rateYoghaPackageTax = Math.floor(response.data.yogha_pack)
                    setExpensesData({
                        cleaningValue: Number(valueCleaning),
                        condominiumValue: Number(rateCondominium.toFixed(0)),
                        iptuValue: Number(rateIptu.toFixed(0)),
                        yoghaPackageTax: Number(rateYoghaPackageTax.toFixed(0)),
                        midStay: sumMidStay,
                        id: response.data.id,
                        longStay: Number(response.data.longstay_value),
                        monthlyValue: Number(response.data.sumMonth),
                        uniqueValue: Number(response.data.uniqueValue),
                        definedDailyValue: Number(response.data.sumDailyValues),
                    });
                } else {
                    setExpensesData({
                        cleaningValue: Number(valueCleaning),
                        midStay: sumMidStay,
                        monthlyValue: Number(response.data.sumMonth),
                        uniqueValue: Number(response.data.uniqueValue),
                        definedDailyValue: Number(response.data.sumDailyValues),
                        condominiumValue: Number(response.data.condominium),
                        iptuValue: Number(response.data.iptu),
                        yoghaPackageTax: Number(response.data.yogha_pack),
                        id: response.data.id,
                        longStay: Number(response.data.longstay_value)
                    })
                }
                setIdAccomodation(response.data.idAccommodations)
            } else {
                const response = await fetchAxios.post(`/accommodations/accommodationValue/${id}`,)
                const filteredFinalCleaningServices = filterServices(response.data.services, "Limpeza Final");
                const filteredExtraCleaningServices = filterServices(response.data.services, "Limpeza Extra");
                const valueCleaning = filteredFinalCleaningServices.length > 0 ? filteredFinalCleaningServices[0].value : 0
                const reserveServiceArray = generateReserveServices(filteredFinalCleaningServices, filteredExtraCleaningServices, weekValue);
                const midStay = Number(response.data.midStayTaxa)
                const sumMidStay = Number(midStay.toFixed(0))
                setReservationDetails({
                    accommodation_code: response.data.code,
                    accommodation_id: response.data.accommodation_id,
                    ref_stays: response.data.ref_stays,
                    hostingValue: realTotalValue as number,
                    amount: '1',
                    city: response.data.town,
                    code: filteredFinalCleaningServices.length > 0 ? filteredFinalCleaningServices[0].service_id : 0,
                    reserveServices: reserveServiceArray,
                })
                setExpensesData({
                    id: response.data.id,
                    cleaningValue: Number(valueCleaning),
                    condominiumValue: Number(response.data.condominium),
                    iptuValue: Number(response.data.iptu),
                    yoghaPackageTax: Number(response.data.yogha_pack),
                    longStay: Number(response.data.longstay_value),
                    midStay: sumMidStay,
                    monthlyValue: Number(response.data.midStayTaxa) ? sumMidStay : Number(response.data.sumMonth),
                    uniqueValue: Number(response.data.uniqueValue),
                    definedDailyValue: Number(response.data.sumDailyValues)
                })
                setMinGuests(response.data.minimun_guests)
                setMaxGuest(response.data.max_guest_capacity)
                setGuestAddtional(response.data.guest_addtional)
                setIdAccomodation(response.data.idAccommodations)
            }
        } catch (error) {
        }
    }

    useEffect(() => {
        if (date && date.length === 0) {
            const query = new URLSearchParams(location.search);

            const checkIn = query.get('check_in');
            const checkOut = query.get('check_out');

            // Verifique a validade antes de criar objetos dayjs
            if (checkIn && checkOut && dayjs(checkIn, 'YYYY-MM-DD').isValid() && dayjs(checkOut, 'YYYY-MM-DD').isValid()) {
                // Crie objetos dayjs
                const dayCheckIn = checkIn;
                const dayCheckOut = checkOut;

                setDate([dayCheckIn, dayCheckOut]);

            } else {

            }
        }
        getDailyValue()
    }, [setDate, difference])

    useEffect(() => {
        setTotalValue(Number(totalValue(difference)))
    }, [totalValue, difference])

    // useEffect(() => {
    //     if (maxGuest !== null) {
    //         const query = new URLSearchParams(location.search);

    //         const guestsParams = query.get('guests');

    //         if (guestsParams && maxGuest && Number(guestsParams) > maxGuest) {
    //             updateQueryParam({ 'guests': maxGuest })
    //             setGuest(maxGuest)
    //         } else if (guestsParams && Number(guestsParams) < 1) {
    //             updateQueryParam({ 'guests': 1 })
    //             setGuest(1)
    //         } else {
    //             updateQueryParam({ 'guests': Number(guestsParams) })
    //         }
    //     }
    // }, [maxGuest])

    useEffect(() => {
        // Converta guestsParams para um número e valide
        const guestsParams = parseInt(searchParams.get('guests') || '0', 10);

        // Se maxGuest estiver definido e o valor da URL for inválido, ajuste-o
        if (maxGuest !== null) {
            if (guestsParams > maxGuest) {
                setGuest(maxGuest);
                //updateQueryParam({ 'guests': maxGuest });
            } else if (guestsParams < 1) {
                //updateQueryParam({ 'guests': 1 });
                setGuest(1);
            } else {
                // Somente atualize o estado se o parâmetro for um número válido
                setGuest(guestsParams);
            }
        }
    }, [maxGuest, searchParams, setSearchParams]);


    const discountDifference = expensesData && (expensesData.definedDailyValue * difference) - (expensesData.definedDailyValue * difference * 0.1)

    const discountValue = expensesData && (expensesData.definedDailyValue * difference * 0.1)

    const handleReserve = () => {
        if (!expensesData) return;

        setReserveDetails(setReserveData)

        const sumValues = expensesData && expensesData.condominiumValue + expensesData.iptuValue + expensesData.midStay + expensesData.yoghaPackageTax + (weekValue * expensesData.cleaningValue)

        const totalMidStayWithGuest = (Number(totalValue(difference)) - sumValues) + expensesData.midStay

        const dataSession: DataSessionPaymentProps = {
            reserveDetails: setReserveData,
            weekValue,
            difference,
            nightValue: totalGuests >= 3 ? Number(totalValue(difference)) - expensesData.cleaningValue : Number(expensesData.definedDailyValue.toFixed(0)),
            total: Number(totalV.toFixed(0)),
            discount,
            condominiumValue: Number(expensesData.condominiumValue.toFixed(0)),
            midStayValue: totalGuests >= 3 ? totalMidStayWithGuest : Number(expensesData.midStay.toFixed(0)),
            iptu: Number(expensesData.iptuValue.toFixed(0)),
            cleaningValue: Number(expensesData.cleaningValue * weekValue),
            pacoteYogha: Number(expensesData.yoghaPackageTax.toFixed(0))
        }
        setPaymentData(dataSession)
        const rentalPriceCalc = () => {
            if (totalV) {
                const total = reservationDetails.reserveServices.reduce((acc, service) => {
                    return acc + (service.amount * service.value);
                }, 0);
                return Number(totalV) - total;
            }
        };

        const rentalPrice = rentalPriceCalc();

        const reserverData: ReserverDataProps = {
            "accommodation_code": reservationDetails.accommodation_code,
            "accommodation_id": reservationDetails.accommodation_id,
            "arrival_date": date && formatDateMapped(date[0]),
            "departure_date": date && formatDateMapped(date[1]),
            "booking_type": "UNPAID",
            "customer_id": userCustomerId,
            "city": reservationDetails.city,
            "email": userEmail,
            "adults_number": totalGuests,
            "name": userName,
            "ref_stays": reservationDetails.ref_stays,
            "rental_price": rentalPrice,
            "total_price": totalV,
            "surname": userSurname,
            "document": userDocument,
            "telephone": userPhone,
            "services": reservationDetails.reserveServices,
            "creditCardToken": creditCardToken,
            "nameAcc": reservationDetails.nameAcc,
            "nameBuilding": reservationDetails.nameBuilding
        }

        saveToSessionStorage('reserver', reserverData)

        if ((date && date.length === 0) || (date && date[0] === null && date[1] === null)) {
            showAlert({ active: true, title: 'Selecione uma data para continuar a reserva', type: "ERROR" })
            return;
        }
        if (userName.length === 0) {
            navigate('/login')
        } else {
            if (date && date[0] && date[1]) {
                // Gerar um hash simples (você pode usar uma biblioteca para gerar UUIDs ou outros hashes mais seguros)
                const hash = Math.random().toString(36).substring(2, 15);

                // Salvar o objeto e a marca de tempo no localStorage
                const currentTime = new Date().getTime(); // Tempo atual em milissegundos
                localStorage.setItem(hash, JSON.stringify({ reserveData: setReserveData, time: currentTime, expensesData, date, totalValue: totalReserveValue }));


                // Navegar para a nova página
                navigate(`/payment`);
            } else {
                showAlert({ active: true, title: 'Selecione uma data para continuar a reserva', type: "ERROR" })
            }
        }

    }

    useEffect(() => {
        const getDaysReserve = async () => {
            try {
                const { data } = await fetchAxiosAdmin.get(`/avantio/get-booking-availability/${idAccomodation}`);
                const blockTypes = ['checkin', 'checkout', 'ocupada', 'checkin-checkout'];
                const filteredByBlockTypes = data.original.filter((block: any) => blockTypes.includes(block.status));
                setBlockedDates(filteredByBlockTypes)
            } catch (error) {
                console.error('error', error)
            }
        }
        if (idAccomodation) {

            getDaysReserve();
        }

    }, [idAccomodation])


    useEffect(() => {
        if (includeTax && expensesData) {
            const valueTotalTax = Number(((expensesData.cleaningValue * 5) + expensesData.midStay + expensesData.condominiumValue + expensesData.iptuValue + expensesData.yoghaPackageTax).toFixed(0));
            const valueTax = Number(Number(expensesData.definedDailyValue + expensesData.cleaningValue).toFixed(0))
            setHasTaxSearch(true)
            setValueWithTax(valueTax);
            setTotalValueWithTax(valueTotalTax);
        }
    }, [includeTax, realTotalValue, expensesData]);

    const warningSimbol = require('../../../../assets/svg/WarningCircle.svg').default;
    return (
        <>
            {isFetching ? (
                <>
                    <Skeleton width={455} height={495} style={{
                        marginLeft: 64
                    }} />
                </>
            ) : (
                <S.Container>
                    <S.ContainerCustomStay>
                        <S.TitleCard>Customize sua estadia:</S.TitleCard>
                        <S.LabelContainer>
                            <p>Data de Entrada </p> <p>Data de Saída </p>
                        </S.LabelContainer>
                        <S.InputContainer>
                            <CalendarComponent noHasBorder inputDatesWidth='100%' blockedDates={blockedDates} date={date as Date[]} setDate={setDate} minimumStay={minimumStay} />
                            <S.ContainerGuest>
                                <S.ContentGuest>
                                    <GuestSelector maxGuests={maxGuest ?? 0} fill isCard />
                                </S.ContentGuest>
                            </S.ContainerGuest>
                        </S.InputContainer>
                        <S.Content>
                            {/* Mais de um mês */}
                            {difference > 26 && <S.PaymentContainer>
                                <S.Rows discount={discount}>
                                    <p>Aluguel</p>
                                    <S.ContainerValues discount={discount}>
                                        {discount && <span>De</span>}
                                        <label>R$</label>
                                        <S.RowValues discount={discount}>
                                            {expensesData && expensesData.midStay},00
                                        </S.RowValues>
                                    </S.ContainerValues>
                                    {discount && <S.ContainerValues >
                                        <label>R$</label>
                                        <S.RowValues>
                                            {discountDifference && discountDifference.toLocaleString('pt-BR')}
                                        </S.RowValues>
                                    </S.ContainerValues>}
                                    {discount && <S.Discount>
                                        Você economiza R$ {discountValue && discountValue.toLocaleString('pt-BR')},00(5%)
                                    </S.Discount>}
                                </S.Rows>
                                <S.Rows>
                                    <p>Condomínio</p>
                                    <S.ContainerValues>
                                        <label>R$</label>
                                        <S.RowValues>
                                            {expensesData && expensesData.condominiumValue},00
                                        </S.RowValues>
                                    </S.ContainerValues>
                                </S.Rows>
                                <S.Rows>
                                    <p>IPTU</p>
                                    <S.ContainerValues>
                                        <label>R$</label>
                                        <S.RowValues>
                                            {expensesData && expensesData.iptuValue},00</S.RowValues>
                                    </S.ContainerValues>
                                </S.Rows>
                                <S.Rows>
                                    <S.CleaningRow>
                                        <p>{weekValue} Limpeza{weekValue > 1 && 's'} </p> <Tooltip text='As limpezas serão feitas a cada 7 dias, sendo a primeira no sétimo dia e a última, após a saída do hóspede. Em estadias de até 7 dias, a limpeza ocorrerá após o checkout.' />
                                    </S.CleaningRow>
                                    <S.ContainerValues>
                                        <label>R$</label>
                                        <S.RowValues>
                                            {expensesData && expensesData.cleaningValue * weekValue},00</S.RowValues>
                                    </S.ContainerValues>
                                </S.Rows>
                                <S.Rows>
                                    <p>Pacote Yogha</p>
                                    <S.ContainerValues>
                                        <label>R$</label>
                                        <S.RowValues>
                                            {expensesData && expensesData.yoghaPackageTax},00</S.RowValues>
                                    </S.ContainerValues>
                                </S.Rows>
                                {additionalValueGuest > 0 && <S.Rows>
                                    <p>Taxa de acompanhantes</p>
                                    <S.ContainerValues>
                                        <label>R$</label>
                                        <S.RowValues>
                                            {additionalValueGuest},00</S.RowValues>
                                    </S.ContainerValues>
                                </S.Rows>}
                                <S.TotalRow>
                                    <S.Total>Total</S.Total>
                                    <S.ContainerValues>
                                        <S.PrefixTotal>R$</S.PrefixTotal>
                                        <S.TotalValue>
                                            {totalValue(difference)},00
                                        </S.TotalValue>
                                    </S.ContainerValues>
                                </S.TotalRow>
                                <S.FooterButtons>
                                    <Button widthSize={"100%"} icon='' size='large' onClickButton={handleReserve} type='primary' title='Reservar' />
                                </S.FooterButtons>
                            </S.PaymentContainer>
                            }
                            {/* Default */}
                            {difference === 0 &&
                                <S.DefaultCard>
                                    <S.PaymentDefault>
                                        <S.TextCardDefault>
                                            Até 26 noites:
                                        </S.TextCardDefault>
                                        <S.LittleText>
                                            A partir de
                                        </S.LittleText>
                                        <S.Total>
                                            R$ {hasTaxSearch ? valueWithTax : expensesData && expensesData.uniqueValue.toFixed(0)},00/noite
                                        </S.Total>
                                    </S.PaymentDefault>
                                    <S.Divisor>
                                        <p>
                                            ou
                                        </p>
                                    </S.Divisor>
                                    <S.PaymentDefault>
                                        <S.TextCardDefault>
                                            Total mensal:
                                        </S.TextCardDefault>
                                        <S.LittleText>
                                            A partir de
                                        </S.LittleText>
                                        <S.Total>
                                            R$ {expensesData && expensesData.midStay !== null && hasTaxSearch ? totalValueWithTax : expensesData && expensesData.midStay !== null ? expensesData.midStay : expensesData && expensesData.monthlyValue.toFixed(0)},00/mês
                                        </S.Total>
                                    </S.PaymentDefault>
                                    <S.FooterButtons>
                                        <Button widthSize={"100%"} icon='' size='large' onClickButton={handleReserve} type='primary' title='Reservar' />
                                    </S.FooterButtons>
                                </S.DefaultCard>}
                            {/* Menos de um mês */}
                            {difference <= 26 && difference !== 0 && <S.PaymentContainer>
                                <S.Rows discount={discount}>
                                    <p>{difference} Noites</p>
                                    <S.ContainerValues discount={discount}>
                                        {discount && <span>De</span>}
                                        <label>R$</label>
                                        <S.RowValues discount={discount}>
                                            {expensesData && expensesData.definedDailyValue.toFixed(0)},00
                                        </S.RowValues>
                                    </S.ContainerValues>
                                    {discount && <S.ContainerValues >
                                        <label>R$</label>
                                        <S.RowValues>
                                            {discountDifference && discountDifference.toLocaleString('pt-BR')}
                                        </S.RowValues>
                                    </S.ContainerValues>}
                                    {discount && <S.Discount>
                                        Você economiza R$ {discountValue && discountValue.toLocaleString('pt-BR')},00(5%)
                                    </S.Discount>}
                                </S.Rows>
                                <S.Rows>
                                    <S.CleaningRow>
                                        <p>{weekValue} Limpeza{weekValue > 1 && 's'} </p> <Tooltip text='As limpezas serão feitas a cada 7 dias, sendo a primeira no sétimo dia e a última, após a saída do hóspede. Em estadias de até 7 dias, a limpeza ocorrerá após o checkout.' />
                                    </S.CleaningRow>
                                    <S.ContainerValues>
                                        <label>R$</label>
                                        <S.RowValues>
                                            {expensesData && expensesData.cleaningValue * weekValue},00</S.RowValues>
                                    </S.ContainerValues>
                                </S.Rows>
                                <S.Rows>
                                    <p>Pacote Yogha</p>
                                    <S.ContainerValues include>
                                        <label>incluso</label>
                                    </S.ContainerValues>
                                </S.Rows>
                                {additionalValueGuest > 0 && <S.Rows>
                                    <p>Taxa de acompanhantes</p>
                                    <S.ContainerValues>
                                        <label>R$</label>
                                        <S.RowValues>
                                            {additionalValueGuest},00</S.RowValues>
                                    </S.ContainerValues>
                                </S.Rows>}
                                <S.TotalRow>
                                    <S.Total>Total</S.Total>
                                    <S.ContainerValues>
                                        <S.PrefixTotal>R$</S.PrefixTotal>
                                        <S.TotalValue>
                                            {totalValue(difference)},00
                                        </S.TotalValue>
                                    </S.ContainerValues>
                                </S.TotalRow>
                                <S.FooterButtons>
                                    <Button widthSize={"100%"} icon='' size='large' onClickButton={handleReserve} type='primary' title='Reservar' />
                                </S.FooterButtons>
                            </S.PaymentContainer>}

                            <S.Footer>

                                <p>Você não será cobrado agora</p>
                            </S.Footer>
                        </S.Content>
                        <Alert />

                    </S.ContainerCustomStay>
                    <S.ContainerPaymentsMethods>
                        <S.ContentPaymentMethods>
                            <strong>
                                Métodos de pagamento:
                            </strong>
                            <S.PaymentMethodRow>
                                <img src={pixIcon} alt="Ícone Pix" />
                                <p>PIX com 5% de desconto</p>
                            </S.PaymentMethodRow>
                            <S.PaymentMethodRow>
                                <img src={creditCardIcon} alt="Ícone Cartão de Crédito" />
                                <p>Cartão de Crédito em até 10x com juros</p>
                            </S.PaymentMethodRow>
                        </S.ContentPaymentMethods>
                        {date && date[0] && date[1] && <S.CalcelPolitcs>
                            <div>
                                {daysPermitedToCancel > 6 ? (
                                    <S.PaymentMethodRow>
                                        <CheckIcon size={16} color='black' />
                                        <p>Cancelamento gratuito até o dia {cancelDate}.</p>
                                    </S.PaymentMethodRow>
                                ) : (
                                    <S.PaymentMethodRow style={{
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                    }} >
                                        {/* <img src={closeIcon} alt="Ícone cancelamento" /> */}
                                        <p>Cancelamento gratuito não aplicável para as datas selecionadas.</p>
                                    </S.PaymentMethodRow>
                                )}
                            </div>
                            <S.MethodPaymentLink to={'/termos-e-condicoes'} > Saiba mais sobre nossas politicas de cancelamento</S.MethodPaymentLink>
                        </S.CalcelPolitcs>}
                    </S.ContainerPaymentsMethods>
                </S.Container>
            )}
        </>
    )
}