import { useEffect, useState, } from "react"
import { Footer } from "components/Footer"
import { HeaderAlt } from "components/HeaderAlt"
import { Inputs } from "components/Input"
import { PaymentCard } from "pages/Payment/components/PaymentCard"
import { Button } from "components/Button"
import * as S from "./styles"
import { Outlet, useLocation, useNavigate, useOutletContext } from "react-router-dom"
import { Loading } from "./components/Loading"
import { usePaymentProvider } from "context/paymentContext"
import { RegisterCreditCard } from "./components/RegisterCreditCard"
import fetchAxios from "services/http"
import { useUserProvider } from "hooks/userContext"
import { SkeletonCustonStayCard } from "components/Skeletons/SkeletonPaymentCard"
import useIsMobile from "pages/Mobile/useMobile"
import { useAnyProvider } from "hooks/Context"
import dayjs from "dayjs"
import { ProductCard } from "components/ProductCard"
import { WhatsappButton } from "components/WhatsappButton"
import { BlockedUserAlert } from "./components/BlockedUserAlert"
import { usePaymentTypeProvider } from "./hooks/usePaymentType"
import { useDiscountCookie } from "./components/PaymentCard/useDiscountCupomCookie"
import { useScreenWidth } from "utils/useScreenWidth"


export type PaymentTypeState = {
    type: string;
    cardId: string;
};


interface FetchingProps {
    isFetching: boolean;
    setIsFetching: React.Dispatch<React.SetStateAction<boolean>>;
}

export function useFetchingPayment() {
    return useOutletContext<FetchingProps>();
}

export const Payment = () => {

    document.title = "Efetue o pagamento da reserva | Yogha";

    const breakpoint = useScreenWidth()

    const { isDiscountValid, discount } = useDiscountCookie();

    const cupomDiscount = discount ? Number(discount) : 0

    const [cards, setCards] = useState<any[]>()
    const [reload, setReload] = useState<boolean>(false)
    const [isFetching, setIsFetching] = useState<boolean>(false)
    const [isReserve, setIsReserve] = useState<boolean>(false)
    const [isBlocked, setIsBlocked] = useState<boolean>(false)
    const [openNotifyPix, setOpenNotifyPix] = useState<boolean>(false)

    const isMobile = useIsMobile()

    const location = useLocation();

    const navigate = useNavigate();

    const { clearDiscount } = useDiscountCookie();

    const { date } = useAnyProvider()
    const { isLoading, reserveDetails } = usePaymentProvider()


    useEffect(() => {

        const getDaysReserve = async () => {
            try {
                const { data } = await fetchAxios.get(`/accommodations/notFreeCalendar/${reserveDetails.id}`);
                const filterData = data.map((item: any) => {
                    return item.date
                })
                if (filterData.includes(date && dayjs(date[0]).format('YYYY-MM-DD')) || filterData.includes(date && dayjs(date[1]).format('YYYY-MM-DD'))) {
                    setIsReserve(true)
                }
            } catch (error) {

            }
        }
        getDaysReserve();
    }, []);

    const { token, statusAccount } = useUserProvider()

    const [openRegisterCard, setOpenRegisterCard] = useState<boolean>(false)


    const [paymentType, setPaymentType] = useState<PaymentTypeState>({ type: '', cardId: '' });
    const { setTypePayment } = usePaymentTypeProvider()


    useEffect(() => {
        if (window.location.pathname === '/payment') {
            setTypePayment(paymentType.type)
        }
    }, [paymentType.type])

    useEffect(() => {
        const getCreditCards = async () => {
            try {
                const res = await fetchAxios.get('payment/cards', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                })
                setCards(res.data)
            } catch (error) {
            }

        }
        getCreditCards()

    }, [reload, openRegisterCard])

    const isPayment = location.pathname === `/payment`;

    const handlePayment = () => {
        if (paymentType.type === 'PIX') {
            if (cupomDiscount <= 5 && isDiscountValid) {
                setOpenNotifyPix(true)
            } else {
                navigate('/payment/type_payment=PIX')
            }
        } else if (paymentType.type !== '') {
            navigate('/payment/type_payment=CREDIT_CARD')
        } else {
            return
        }
    }

    const handleToPixPayment = () => {
        setOpenNotifyPix(false)
        clearDiscount()
        navigate('/payment/type_payment=PIX')
    }

    const plusIcon = require('assets/svg/payment/Plus.svg').default

    const handleReload = () => {
        setTimeout(() => {
            setReload(!reload)
        }, 3000);
    }


    const handleRegisterCard = () => {
        if (statusAccount === "Bloqueado") {
            setIsBlocked(true)
        } else if (isMobile) {
            navigate('/register-card')
        } else {
            setOpenRegisterCard(true)
        }
    }

    return (<>
        {isLoading ? <Loading /> : null}
        <S.Main>
            <HeaderAlt />
            <S.GridContent>
                <S.Content>
                    <S.SectionPayment>

                        {isPayment ? <>
                            <S.PaymentOption>
                                <h2>Como você prefere pagar?</h2>
                                <S.ContainerInput>
                                    <S.InputPayment>
                                        <Inputs.Radio setReload={setReload} verify={true} cardId="PIX" lastNumber="" token="" brand='PIX' type="PIX" paymentType={paymentType} setPaymentType={setPaymentType} hasCupom={isDiscountValid} />
                                    </S.InputPayment>
                                    {cards?.map((card) => (
                                        <S.InputPayment key={card.id} onClick={() => setPaymentType(card.id)}>
                                            <Inputs.Radio setReload={setReload} onClick={handleReload} verify={card.validated} lastNumber={card.last_numbers} cardId={card.id} token={card.token} brand={card.brand} type='CREDIT_CARD' paymentType={paymentType} setPaymentType={setPaymentType} />
                                        </S.InputPayment>
                                    ))}

                                    {cards && cards.length < 5 ? <S.InputPayment onClick={handleRegisterCard}>
                                        <img src={plusIcon} alt="" />
                                        <p>Adicionar cartão de crédito</p>
                                    </S.InputPayment>
                                        : null}
                                </S.ContainerInput>

                            </S.PaymentOption>
                            <S.ButtonContainer>
                                <Button onClickButton={handlePayment} size="medium" title="Usar essa forma de pagamento" type="primary" widthSize={'100%'} />
                            </S.ButtonContainer>
                        </> : <Outlet context={{ isFetching, setIsFetching } satisfies FetchingProps} />}
                    </S.SectionPayment>
                    <S.CardPaymentContainer>
                        {isFetching ? (
                            <SkeletonCustonStayCard />
                        ) : (
                            <PaymentCard discount={false} onClick={() => { }} onClickCupom={() => { }} accommodation={reserveDetails} />
                        )}
                    </S.CardPaymentContainer>
                </S.Content>

            </S.GridContent>
            <Footer />
            <WhatsappButton />
        </S.Main>
        {openRegisterCard && <RegisterCreditCard setReload={setReload} open={openRegisterCard} setOpen={setOpenRegisterCard} />}
        {/* {isReserve && <ProductCard omitCloseButton bottomLine isOpen={isReserve} setIsOpen={setIsReserve} title="Data da reserva indisponível" >
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 36,
                marginTop: 8
            }}>
                <p> Esta unidade encontra-se indisponível para as datas selecionadas. Retorne à página da acomodação para alterar a sua reserva.</p>
                <div style={{
                    width: 150
                }}>
                    <Button onClickButton={() => navigate(-1)} size="large" title="Voltar" type="primary" widthSize={'100%'} />
                </div>
            </div>

        </ProductCard>} */}

        {openNotifyPix && <ProductCard omitCloseButton bottomLine isOpen={openNotifyPix} setIsOpen={setOpenNotifyPix} title="Desconto Pix Aplicado" width={breakpoint ? 'calc(100% - 48px )' : ''} >
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 36,
                marginTop: 8
            }}>
                <p>O desconto via Pix é maior do que o aplicado pelo cupom. Para continuar, removeremos o cupom atual. Você ainda poderá usar este cupom em outra forma de pagamento.</p>

                <Button onClickButton={handleToPixPayment} size="large" title="Entendido" type="primary" widthSize={'100%'} />
            </div>
        </ProductCard>}
        <BlockedUserAlert blockedUser={isBlocked} setIsBlockedUser={setIsBlocked} />
    </>
    )
}
